import React, { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Link from "next/link";
import Image from "next/image";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useRouter } from "next/router";
import { InputText } from "primereact/inputtext";
import Revenue from "../Revenue/index";
import Router from "next/router";
import MixpanelRecord from "../MixpanelEvent";
import { useCookies } from "react-cookie";
import { useState } from "react";
import Head from "next/head";

// import Sidenav from "./Sidenav1";

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, ({ min }) => `Required 8 characters`)
    .required("Required password")
    .matches(/[0-9]/, "Required number")
    .matches(/[a-z]/, "Required lowercase")
    .matches(/[A-Z]/, "Required uppercase")
    .matches(/[^\w]/, "Required special char"),
  email: Yup.string()
    .required("Email is Required")
    .matches(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/, "Invalid Email Address"),
});
export default function Login() {
  // let navigate = useHistory();
  const router = useRouter();
  const [username, setUsername] = React.useState();
  const [password, setPassword] = React.useState();
  const [showpass, setShowPass] = React.useState(false);
  const [policies, setPolicy] = useState([]);
  const [cookies, setCookie] = useCookies(["t", "name"]);
  const [name, setName] = React.useState();
  const [land, setLand] = useState();

  // React.useEffect(() => {

  // }, []);

  const PasswordVisiblity = () => {
    setShowPass(showpass ? false : true);
  };
  const listener = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      // console.log("Enter key was pressed. Run your function.");
      event.preventDefault();

      click();
    }
  };
  // document.addEventListener("keydown", listener);
  // return () => {
  //   document.removeEventListener("keydown", listener);
  // };

  // React.useEffect(() => {
  //   // const listener = event => {
  //   //   if (event.code === "Enter" || event.code === "NumpadEnter") {
  //   //     console.log("Enter key was pressed. Run your function.");
  //   //     event.preventDefault();
  //   //     // callMyFunction();
  //   //     click();
  //   //   }
  //   // };
  //   document.addEventListener("keydown", listener);
  //   // return () => {
  //   //   document.removeEventListener("keydown", listener);
  //   // };
  // }, []);

  useEffect(() => {
    // alert("hii")
    fetch("http://52.146.42.10:9992/api/v1/harvis/getPolicies")
      .then((response) => response.json())
      .then((data) => {
        // console.log(data, "data");
        setPolicy(data);
      })
      .catch((err) => {
        // console.log(err.message);
      });
  }, []);

  const click = () => {
    const user = { email: username, password: password };

    // console.log(user.email,"suer");
    sessionStorage.setItem("useremail", user.email);
    // setCookie("t", username, { path: "/" });
    fetch("http://52.146.42.10:9992/api/v1/harvis/login", {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // setName(data.name);
        const policy = data.policyName;
        sessionStorage.setItem("policy", policy);
        if (data.message == "LoggedIn Successfully") {
          setCookie("LoginStatus", data.loginstatus, { path: "/" });
          setCookie("Designation", data.designation, { path: "/" });

          console.log(data.name, "data");
          // setName(data.name);
          // console.log(name, "namexxx");
          setCookie("name", data.name);
          setCookie("t", policy, { path: "/" });
          setCookie("user", username, { path: "/" });
          setCookie("token", data.token, { path: "/" });
          MixpanelRecord("Logged in", {
            message: "Logged in successfully",
          });
          // window.location='Home.html'
          // let cook = document.cookie.slice(2);
          policies.map((i, j) => {
            // console.log(i.policyName, "Policy map ", policy);

            if (i.policyName == policy) {
              //please explain why we are using state
              // setLand(i.landing);

              //  console.log(land,"landing",i.landing)
              // let landingPath = i.landing.endsWith("/")
              //   ? i.landing.slice(0, -1)
              //   : i.landing; // Remove trailing slash if present
              // console.log("land", i.landing)
              router.push({
                pathname: i.landing,
              });
            }
          });
          // alert(policy,">>",i.landing)
          // featureLanding = land;
          // router.push(
          //   {
          //     pathname: "/Home",
          //     query: { username, policy },
          //   },
          //   "/Home"
          // );
        } else {
          alert("Wrong Credentials");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

    // console.log(document.cookie)
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        width: "100vw",
      }}
      className="lot"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        {/* <Sidenav></Sidenav> */}

        <div
          // className="flex flex-column justify-center items-center self-center mt-[50px]"
          style={{
            // marginLeft: 480,
            // marginTop: 80,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            width: 360,
            height: 450,
            backgroundColor: "whitesmoke",
            borderRadius: 8,
            // padding: 50,
          }}
        >
          <Image
            style={{ justifyContent: "center", alignSelf: "center" }}
            alt="Card"
            src="http://digitalstudio.eastus.cloudapp.azure.com/images/harvis.png"
            width={150}
            height={50}
            className="img1"
          ></Image>
          <h2 style={{ textAlign: "center", fontFamily: "Didact Gothic" }}>
            Login
          </h2>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={LoginSchema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                // alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              values,
            }) => (
              <div>
                <div className="flex flex-column gap-4">
                  <div className="flex">
                    <span className="p-input-icon-right">
                      <InputText
                        id="email"
                        style={{ width: 250 }}
                        onChange={handleChange}
                        onKeyDown={listener}
                        type="text"
                        // onChange={()=>{handleChange,console.log(username)}}
                        onBlur={handleBlur}
                        placeholder="User Name"
                        value={username}
                        onInput={(e) => setUsername(e.target.value)}
                      />
                      {touched.email && errors.email && (
                        <div style={{ color: "red" }}>{errors.email}</div>
                      )}
                    </span>
                  </div>
                  {/* {console.log(username, "jinny")} */}
                  <div className="flex">
                    <span className="p-input-icon-right">
                      <i
                        style={{ marginTop: -5 }}
                        className={showpass ? "pi pi-eye-slash" : "pi pi-eye"}
                        onClick={() => setShowPass(!showpass)}
                      />
                      <InputText
                        id="password"
                        style={{ width: 250 }}
                        onChange={handleChange}
                        type="text"
                        onBlur={handleBlur}
                        onKeyDown={listener}
                        // onKeyDown={(e) => (e.key === "Enter" ? handleSubmit : "")}
                        // onKeyDown={keyDownHandler}

                        onInput={(e) => setPassword(e.target.value)}
                        value={password}
                        placeholder="Password"
                        type={showpass ? "text" : "password"}
                      />
                      {touched.password && errors.password && (
                        <div style={{ color: "red" }}>{errors.password}</div>
                      )}
                    </span>
                  </div>
                  <Button
                    value={{ username }}
                    disabled={!isValid}
                    type="submit"
                    // onClick={() =>
                    //   Router.push("/Home", {
                    //     pathname: "/Home",
                    //     query: { Name: username },
                    //   })
                    // }

                    onClick={() => click()}
                    // onClick={() =>
                    //   navigate("/dashboard", (state = { name: username }))
                    // }
                    label="Login"
                    style={{ width: 250 }}
                  />
                  <div style={{ fontSize: 18, textAlign: "center" }}>
                    New user ? <Link href="./Signup">Signup</Link>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
