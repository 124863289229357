// import Head from 'next/head'

import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
// import Sidenav from './Sidenav';
import "primereact/resources/primereact.css"; // core css
import "primeicons/primeicons.css"; // icons
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeflex/primeflex.css";

// import LoginDemo from './api/auth/login';
import Head from "next/head";

// import Dashboard from './dashboard';
import Login from "../pages/Login";

export default function Main() {
  const router = useRouter();

  useEffect(() => {
    router.push("");
  }, []);
  return (
    <>
      <Head>
        <title>Home App</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap"
          rel="stylesheet"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* <link
          href="https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&family=Imperial+Script&display=swap"
          rel="stylesheet"
        /> */}

        {/* <script async src="https://snack.expo.dev/embed.js"></script> */}
      </Head>

      <Login></Login>

      {/* <Home /> */}
    </>
  );
}
